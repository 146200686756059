.SampleForm {
  max-width: 800px;
  margin: 20px auto ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px 15px;
}
.SampleForm h1{
  font-size: 30px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .SampleForm {
    max-width: 600px;
    padding: 0;
  }
  
}
@media screen and (max-width: 650px) {
  .SampleForm {
    margin: 0
  }
  .box{
    width: min-content;
    margin: auto;
  }
}




.file-active {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1000;
  width: 100%;
  background-color: rgba(238, 243, 254, 0.9);
  opacity: 1;
  visibility: visible;
  transition: 500ms all;
}
.file-inactive {
  display: none;
}

/* Centering container */
.center-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; 
  padding: 1rem;
}

.webcam-container {
  height: 100%;
  width: fit-content;
  opacity: 1;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem;
}